.container {
  min-height: 100svh;
  width: 100svw;
  background-color: var(--background-primary);
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}

.header {
  padding: 25px 60px;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
}

@media screen and (max-width: 720px) {
  .header {
    padding: 12px 16px;
  }
}
