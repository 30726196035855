.container {
  height: 140px;
  border-radius: 24px;
  border: var(--main-border-width) solid var(--border-primary);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  box-sizing: border-box;
  min-width: 160px;
  flex: 1;
  cursor: pointer;
  align-items: flex-start;
  position: relative;
}

.container:hover {
  opacity: 0.5;
}

.selected {
  cursor: default;
  background-color: var(--background-green);
}

.selected:hover {
  opacity: 1;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.jumbo {
  position: absolute;
  top: -4px;
  right: -4px;
}
