.container {
  display: flex;
  align-items: center;
  padding: 16px;

  border: 1px #fa5959 solid;
  border-radius: 24px;
  margin-bottom: 24px;
}

.iconWrapper {
  width: 24px;
  height: 24px;
}
