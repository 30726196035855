.wrapper {
  display: 'flex';
  flex-direction: 'column';
  justify-content: 'center';
  color: #ffffff;
}

.title {
  font-size: 24px;
  line-height: 31px;
  color: #ffffff;
  font-weight: 600;
  padding-top: 6px;
}

.list {
  padding: 10px 0 15px 0;
}

.item {
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 10px;
}

.img {
  margin-bottom: 15px;
}

.note {
  font-size: 16px;
  line-height: 24px;
  color: #cb912f;
  margin-bottom: 15px;
}

.finalText {
  font-size: 30px;
  line-height: 39px;
  color: #ffffff;
}
