.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.wrapper {
  background-color: #ffffff;
  max-width: 550px;
  border-radius: 24px;
  padding: 24px;
  display: grid;
}

.title {
  position: relative;
  z-index: 1;

  text-align: center;
  margin-bottom: 8px;

  font-weight: bold;
  font-size: 24px;
  color: #000000;
}

.timer {
  position: absolute;
  top: 0;
  right: 0;
}

.subTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #090f1f;
  margin-bottom: 24px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.buttons > div {
  border-radius: 24px;
}

@media screen and (max-width: 720px) {
  .title {
    font-size: 16px;
  }

  .timer {
    top: -5px;
  }
}
