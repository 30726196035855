.wrapper {
  height: 100svh;
  width: 100svw;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bodyContainer {
  border-radius: 24px;
  background: #1c2131;
  padding: 24px;
  min-width: 400px;
  max-height: 80vh;
  overflow: hidden;
}

.scrollableContent {
  max-height: calc(80vh - 48px);
  overflow-y: auto;
  padding-right: 8px;
}

@media (max-width: 570px) {
  .bodyContainer {
    min-width: 350px;
  }
}

/* Кастомный scroll */
.scrollableContent {
  scrollbar-color: #292e31 #12171a;
  scrollbar-width: thin;
}

.scrollableContent::-webkit-scrollbar {
  width: 6px;
  background-color: #12171a;
}

.scrollableContent::-webkit-thumb,
.scrollableContent::-webkit-scrollbar-thumb {
  background-color: #292e31;
}

/* Для Webkit (Chrome, Safari) */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .scrollableContent {
    scrollbar-color: #292e31 #12171a;
    scrollbar-width: thin;
  }
}

/* Для Firefox */
@-moz-document url-prefix() {
  .scrollableContent {
    scrollbar-color: #292e31 #12171a;
    scrollbar-width: thin;
  }
}

/* Для Opera */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .scrollableContent::-webkit-scrollbar {
    width: 6px;
  }

  .scrollableContent::-webkit-scrollbar-thumb {
    background-color: #292e31;
  }

  .scrollableContent::-webkit-scrollbar-track {
    background-color: #12171a;
  }
}
