.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;
}

.bankContainer {
  background-color: #fff;
  border-radius: 24px;
  padding: 20px;
  max-width: 900px;
  width: 900px;
  min-height: 500px;
  height: 500px;
  overflow-y: auto;
  scrollbar-width: thin;
}
.searchContainer {
  display: flex;
  column-gap: 10px;
  width: 100%;
  align-items: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 992px) {
  .container {
    flex-direction: column;
    height: 50%;
    min-width: 100%;
  }

  .bankContainer {
    width: 100%;
    min-height: 450px;
    height: calc(100vh - 250px);
  }
}

.option {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid transparent;
  border-radius: 24px;
  padding: 16px;
  position: relative;
  cursor: pointer;
  transition: border-color 0.3s;
  border-color: #50d866;
  height: 100%;
}

.selected {
  background-color: #7afb8f;
}

.radio {
  display: none;
}

.inputContainer {
  display: flex;
  border: 1px solid #50d866;
  border-radius: 99px;
  column-gap: 10px;
  padding: 4px;
  width: 100%;
  color: white;
}

.disabled {
  opacity: 0.2;
}

.input {
  width: 100%;
  color: white;
  padding-right: 10px;
}

.input::placeholder {
  color: #979bb0;
}

.input::placeholder {
  color: #979bb0;
}

.amountWrapper {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.jamboWrapper {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: -2px;
  margin-right: -2px;
}

.relativeCard {
  position: relative;
  width: 100%;
}

.noMatches {
  color: #979bb0;
  width: 100%;
  text-align: center;
}

.bankItems {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  gap: 8px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.buttonWrapper {
  display: flex;
  gap: 8px;
  width: 50%;
}

@media (max-width: 870px) {
  .bankItems {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 720px) {
  .bankItems {
    grid-template-columns: repeat(3, 1fr);
  }

  .buttonWrapper {
    width: 100%;
  }
}

@media (max-width: 530px) {
  .bankItems {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 370px) {
  .bankItems {
    grid-template-columns: repeat(1, 1fr);
  }
}
