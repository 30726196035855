.container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.notification {
  padding: 10px 20px;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
}

.info {
  background-color: #007bff;
}

.success {
  background-color: #28a745;
}

.error {
  background-color: #dc3545;
}
