.container {
  display: flex;
  align-items: center;
  margin-bottom: 33px;
  justify-content: center;
}

.doneLine {
  width: 60px;
  height: 2px;
  background: #7afb8f;
}

.nextLine {
  width: 60px;
  height: 2px;
  background: rgba(255, 255, 255, 0.3);
}

.stepName {
  position: absolute;
  bottom: -25px;
  white-space: nowrap;
  transform: translateX(-50%);
  left: 50%;
}
