.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}

.wrapper {
  background-color: #ffffff08;
  width: 500px;
  border-radius: 24px;
  padding: 24px;
}

@media (max-width: 576px) {
  .wrapper {
    width: 100%;
    margin: 24px;
  }
}

.inputWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.titleWrapper {
  text-align: center;
  padding-top: 28px;
  padding-bottom: 48px;
}

.textarea {
  width: 100%;
  height: 100%;
  word-break: break-word;
  height: 110px;
}

.maxSymbols {
  color: #757c8d;
  font-size: 10px;
  line-height: 24px;
  font-weight: 600;
}

.inputContainer {
  padding: 15px 15px;
  display: flex;
  align-items: center;
  border: 1px solid #dddddd;
  border-radius: 10px;
  margin-top: 10px;
  background-color: #d9d9d9;
}

.inputContainer input {
  width: 100%;
}

.inputContainer input::placeholder {
  color: #756f6f;
}

.rowFlexContainer {
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  align-items: flex-start;
}

.columnContainer {
  width: 250px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding-top: 30px;
}

.buttonContainer {
  padding-bottom: 14px;
  padding-top: 8px;
}

.dragging {
  border: 2px dashed #007bff;
  background-color: rgba(0, 123, 255, 0.05);
}
.deleteButton {
  background: none;
  border: none;
  color: #979bb0;
  cursor: pointer;
  font-size: 16px;
  padding: 0 8px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease;
  height: 16px;
  min-height: 16px;
}

.deleteButton:hover {
  color: #666;
}

.inputContainer {
  display: flex;
  align-items: center;
}

.fileInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.fileIcon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.imagePreview {
  object-fit: cover;
  border-radius: 4px;
  flex-shrink: 0;
}
