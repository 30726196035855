.titleContainer {
  width: 100%;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.buttonsRow {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.amountContainer {
  display: flex;
  width: 100%;
  padding: 12px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: var(--main-border-radius);
  background: var(--background-primary);
}

.amountContainerTitle {
  color: #979bb0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
}

.amountContainerAmount {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 590;
  line-height: 16px; /* 100% */
}

@media screen and (max-width: 720px) {
  .container {
    padding: 24px 24px;
  }

  .buttonsRow {
    flex-direction: column;
  }
}
