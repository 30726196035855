.container {
  background-color: var(--background-content-primary);
  border-radius: var(--main-border-radius);
  padding: 24px 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  box-sizing: border-box;
}

.alignCenter {
  align-items: center;
}

@media screen and (max-width: 720px) {
  .container {
    padding: 24px 24px;
  }
}
