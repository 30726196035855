.credentials {
  position: relative;
  z-index: 1;

  max-width: 502px;
  height: 200px;
  padding: 16px;
  margin-bottom: 24px;
  border-radius: 24px;

  display: grid;
  grid-template-columns: 4fr 1fr;
  align-items: center;
  justify-content: space-between;

  background: linear-gradient(99deg, #25a458 0%, #058d44 100%);
}

.credentials div:nth-child(even) {
  justify-self: end;
}

.credentials::before {
  content: attr(data-bank);
  color: #ffffff3d;
  padding-right: 24px;
  font-size: 55px;
  line-height: 132px;
  font-weight: bold;
  text-align: right;

  display: inline-block;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;

  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

@media screen and (min-width: 520px) {
  .credentials::before {
    width: 400px;
  }
}

.credentialsTextWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.credentialsText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

.button {
  cursor: pointer;
}
