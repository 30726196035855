.wrapper {
  display: flex;
  flex-direction: column;

  max-width: 400px;
  gap: 10px;
}

.header {
  position: relative;
  z-index: 1;

  text-align: center;
}

.buttonClose {
  position: absolute;
  top: 4px;
  right: 0;
  z-index: 1;
}

.button:hover {
  cursor: pointer;
  opacity: 0.5;
}

@media (max-width: 570px) {
  .buttonClose {
    top: -1px;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  padding-top: 20px;
}

.spinner {
  display: flex;
  justify-content: center;
}
