.container {
  height: 56px;
  min-height: 56px;
  line-height: 24px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 6px;
  border-radius: 999px;
  font-weight: 600;
  flex: 1;
}

.container:hover {
  cursor: pointer;
  filter: brightness(1.1);
}

.primary {
  background-color: #7afb8f;
  color: #090f1f;
}

.gray {
  background-color: #65687a;
  color: #ffffff;
}

.danger {
  background-color: #fa5959;
  color: #090f1f;
}

.outline {
  border: 1px solid #979bb0;
  background-color: transparent;
  color: white;
  height: 40px;
  min-height: 40px;
  font-weight: 400;
  font-size: 14px;
}

.disabled {
  opacity: 0.4;
}

.disabled {
  cursor: default !important;
  filter: none !important;
}

.rounded {
  border-radius: 999px;
}

@media screen and (max-width: 720px) {
  .container {
    font-size: 14px;
    line-height: 18px;
  }
}
