.columnFlexContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}

.spinnerWrapper {
  width: 100%;
  text-align: center;
  padding-top: 8px;
}
