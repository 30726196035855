@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: rotate 1s linear infinite;
  height: 60px;
  width: 60px;
}

@media screen and (max-width: 720px) {
  .spinner {
    height: 40px;
    width: 40px;
  }
}
