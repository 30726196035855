.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}

.wrapper {
  background-color: #ffffff08;
  width: 500px;
  border-radius: 24px;
  padding: 24px;
}

@media (max-width: 576px) {
  .wrapper {
    width: 100%;
    margin: 24px;
  }
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: center;
  padding-top: 28px;
}

.buttonContainer {
  display: flex;
  column-gap: 12px;
  padding-bottom: 14px;
  padding-top: 32px;
}

.buttonContainer > div,
.buttonContainer > button {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imgContainer {
  width: 100%;
  text-align: center;
  padding-top: 8px;
}
