.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: center;
  position: relative;
}

@media (max-height: 500px) and (orientation: landscape) {
  .container {
    padding-top: 80px;
  }
}

.progressBar {
  position: absolute;
  top: 0;
}
