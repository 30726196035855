.title {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.title1 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.title1Medium {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.counter {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.caption1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.caption1Semibold {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.caption1Medium {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.caption2 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.colorPrimary {
  color: var(--text-primary);
}

.colorPrimaryLight {
  color: var(--text-primary-light);
}

.colorDescription {
  color: var(--text-description);
}

.colorSecondary {
  color: var(--text-secondary);
}

.textAlignStart {
  text-align: start;
}

.textAlignCenter {
  text-align: center;
}

.textAlignEnd {
  text-align: end;
}

@media screen and (max-width: 720px) {
  .title {
    font-size: 16px;
    line-height: 20px;
  }

  .title1Medium {
    font-size: 16px;
    line-height: 20px;
  }

  .caption2 {
    font-size: 12px;
    line-height: 16px;
  }
}
