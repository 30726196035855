.wrapper {
  display: flex;
  flex-direction: column;

  max-width: 400px;
  gap: 10px;
}

.header {
  position: relative;
  z-index: 1;

  text-align: center;
}

.buttonClose {
  position: absolute;
  top: 4px;
  right: 0;
  z-index: 1;
}

.button:hover {
  cursor: pointer;
  opacity: 0.5;
}

.instructionsButton {
  background-color: #007bff;
  color: #ffffff;
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition:
    background-color 0.3s,
    transform 0.2s;
}

.instructionsButton:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.instructionsButton:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

@media (max-width: 570px) {
  .buttonClose {
    top: -1px;
  }
}
