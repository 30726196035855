.paymentContainer {
  background-color: #fff;
  border-radius: 24px;
  padding: 20px;
  width: 420px;
}

.amountContainer {
  background-color: #ffffff12;
  border-radius: 24px;
  padding: 20px;
  width: 420px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  gap: 8px;
}

@media (max-width: 576px) {
  .paymentContainer {
    width: 100%;
  }

  .amountContainer {
    width: 100%;
  }

  .container {
    flex-direction: column;
    height: 100%;
  }
}

.option {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid transparent;
  border-radius: 24px;
  padding: 16px;
  position: relative;
  cursor: pointer;
  transition: border-color 0.3s;
  border-color: #50d866;
  height: 100%;
}

.option:hover {
  opacity: 0.5;
}

.selected {
  background-color: #7afb8f;
}

.radio {
  display: none;
}

.inputContainer {
  display: flex;
  border: 1px solid #50d866;
  border-radius: 99px;
  column-gap: 10px;
  padding: 4px;
  width: 100%;
  color: white;
}

.disabled {
  opacity: 1;
}

.input {
  width: 100%;
  color: white;
  padding-right: 10px;
}

.input::placeholder {
  color: white;
}

.amountWrapper {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.jamboWrapper {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: -2px;
  margin-right: -2px;
}

.relativeCard {
  position: relative;
  width: 100%;
}

.relativeCardDisabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.relativeCard:hover {
  opacity: 0.5;
}
