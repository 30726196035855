.BigIconButton_container__hasAo {
  height: 140px;
  border-radius: 24px;
  border: var(--main-border-width) solid var(--border-primary);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  box-sizing: border-box;
  min-width: 160px;
  flex: 1 1;
  cursor: pointer;
  align-items: flex-start;
  position: relative;
}

.BigIconButton_container__hasAo:hover {
  opacity: 0.5;
}

.BigIconButton_selected__yS_3e {
  cursor: default;
  background-color: var(--background-green);
}

.BigIconButton_selected__yS_3e:hover {
  opacity: 1;
}

.BigIconButton_disabled__8ugE5 {
  opacity: 0.5;
  cursor: not-allowed;
}

.BigIconButton_jumbo__Fzy8Y {
  position: absolute;
  top: -4px;
  right: -4px;
}

.Text_title__NAzGX {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.Text_title1__zhE5E {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.Text_title1Medium__PuBqT {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.Text_counter__93zUX {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.Text_caption1__Hwd0J {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.Text_caption1Semibold__Mnymk {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.Text_caption1Medium__SMqG0 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.Text_caption2__FmLkO {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.Text_colorPrimary__o2Nbg {
  color: var(--text-primary);
}

.Text_colorPrimaryLight__edxfC {
  color: var(--text-primary-light);
}

.Text_colorDescription__4dZk1 {
  color: var(--text-description);
}

.Text_colorSecondary__Hsm5d {
  color: var(--text-secondary);
}

.Text_textAlignStart__XXWbs {
  text-align: start;
}

.Text_textAlignCenter__Hw6lX {
  text-align: center;
}

.Text_textAlignEnd__afbvk {
  text-align: end;
}

@media screen and (max-width: 720px) {
  .Text_title__NAzGX {
    font-size: 16px;
    line-height: 20px;
  }

  .Text_title1Medium__PuBqT {
    font-size: 16px;
    line-height: 20px;
  }

  .Text_caption2__FmLkO {
    font-size: 12px;
    line-height: 16px;
  }
}

.Button_container__M0gCK {
  height: 56px;
  min-height: 56px;
  line-height: 24px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 6px;
  border-radius: 999px;
  font-weight: 600;
  flex: 1 1;
}

.Button_container__M0gCK:hover {
  cursor: pointer;
  filter: brightness(1.1);
}

.Button_primary__yPP0V {
  background-color: #7afb8f;
  color: #090f1f;
}

.Button_gray__FvoG2 {
  background-color: #65687a;
  color: #ffffff;
}

.Button_danger__Wv7qw {
  background-color: #fa5959;
  color: #090f1f;
}

.Button_outline__8kl_P {
  border: 1px solid #979bb0;
  background-color: transparent;
  color: white;
  height: 40px;
  min-height: 40px;
  font-weight: 400;
  font-size: 14px;
}

.Button_disabled__xMw7u {
  opacity: 0.4;
}

.Button_disabled__xMw7u {
  cursor: default !important;
  filter: none !important;
}

.Button_rounded__Qyu3_ {
  border-radius: 999px;
}

@media screen and (max-width: 720px) {
  .Button_container__M0gCK {
    font-size: 14px;
    line-height: 18px;
  }
}

.Layout_container__jcn8o {
  min-height: 100svh;
  width: 100svw;
  background-color: var(--background-primary);
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}

.Layout_header__7Gw7o {
  padding: 25px 60px;
}

.Layout_content__rDPXi {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1 1;
}

@media screen and (max-width: 720px) {
  .Layout_header__7Gw7o {
    padding: 12px 16px;
  }
}

@font-face {
font-family: 'font';
src: url(/_next/static/media/6de88030df47f7f5-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: 'font';
src: url(/_next/static/media/9a6f4c6ecebf77df-s.p.otf) format('opentype');
font-display: swap;
font-weight: 600;
}@font-face {font-family: 'font Fallback';src: local("Arial");ascent-override: 101.52%;descent-override: 25.72%;line-gap-override: 0.00%;size-adjust: 93.79%
}.__className_b54b3f {font-family: 'font', 'font Fallback'
}

.NotificationList_container__TCp9Y {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.NotificationList_notification__R4tgW {
  padding: 10px 20px;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
}

.NotificationList_info__gV20A {
  background-color: #007bff;
}

.NotificationList_success__3201w {
  background-color: #28a745;
}

.NotificationList_error__Ru0do {
  background-color: #dc3545;
}

.Modal_wrapper__HD6KY {
  height: 100svh;
  width: 100svw;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal_bodyContainer__ocEcJ {
  border-radius: 24px;
  background: #1c2131;
  padding: 24px;
  min-width: 400px;
  max-height: 80vh;
  overflow: hidden;
}

.Modal_scrollableContent__AwmMZ {
  max-height: calc(80vh - 48px);
  overflow-y: auto;
  padding-right: 8px;
}

@media (max-width: 570px) {
  .Modal_bodyContainer__ocEcJ {
    min-width: 350px;
  }
}

/* Кастомный scroll */
.Modal_scrollableContent__AwmMZ {
  scrollbar-color: #292e31 #12171a;
  scrollbar-width: thin;
}

.Modal_scrollableContent__AwmMZ::-webkit-scrollbar {
  width: 6px;
  background-color: #12171a;
}

.Modal_scrollableContent__AwmMZ::-webkit-thumb,
.Modal_scrollableContent__AwmMZ::-webkit-scrollbar-thumb {
  background-color: #292e31;
}

/* Для Webkit (Chrome, Safari) */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .Modal_scrollableContent__AwmMZ {
    scrollbar-color: #292e31 #12171a;
    scrollbar-width: thin;
  }
}

/* Для Firefox */
@-moz-document url-prefix() {
  .Modal_scrollableContent__AwmMZ {
    scrollbar-color: #292e31 #12171a;
    scrollbar-width: thin;
  }
}

/* Для Opera */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .Modal_scrollableContent__AwmMZ::-webkit-scrollbar {
    width: 6px;
  }

  .Modal_scrollableContent__AwmMZ::-webkit-scrollbar-thumb {
    background-color: #292e31;
  }

  .Modal_scrollableContent__AwmMZ::-webkit-scrollbar-track {
    background-color: #12171a;
  }
}

@font-face {
font-family: 'font';
src: url(/_next/static/media/6de88030df47f7f5-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: 'font';
src: url(/_next/static/media/9a6f4c6ecebf77df-s.p.otf) format('opentype');
font-display: swap;
font-weight: 600;
}@font-face {font-family: 'font Fallback';src: local("Arial");ascent-override: 101.52%;descent-override: 25.72%;line-gap-override: 0.00%;size-adjust: 93.79%
}.__className_b54b3f {font-family: 'font', 'font Fallback'
}

.ProgressBar_container__V_6UM {
  display: flex;
  align-items: center;
  margin-bottom: 33px;
  justify-content: center;
}

.ProgressBar_doneLine__o6KtT {
  width: 60px;
  height: 2px;
  background: #7afb8f;
}

.ProgressBar_nextLine__eKx87 {
  width: 60px;
  height: 2px;
  background: rgba(255, 255, 255, 0.3);
}

.ProgressBar_stepName__mOV9o {
  position: absolute;
  bottom: -25px;
  white-space: nowrap;
  transform: translateX(-50%);
  left: 50%;
}

@keyframes Spinner_rotate__QA8de {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Spinner_spinner__7ocE4 {
  animation: Spinner_rotate__QA8de 1s linear infinite;
  height: 60px;
  width: 60px;
}

@media screen and (max-width: 720px) {
  .Spinner_spinner__7ocE4 {
    height: 40px;
    width: 40px;
  }
}

.ContentBox_container__luzZr {
  background-color: var(--background-content-primary);
  border-radius: var(--main-border-radius);
  padding: 24px 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  box-sizing: border-box;
}

.ContentBox_alignCenter__eoOeC {
  align-items: center;
}

@media screen and (max-width: 720px) {
  .ContentBox_container__luzZr {
    padding: 24px 24px;
  }
}

.GhostButton_container__9hOVa {
  display: flex;
  gap: 10px;
  align-items: center;

  color: var(--text-secondary);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  fill: var(--text-secondary);

  margin: 25px 0;
}

.GhostButton_container__9hOVa:hover {
  color: var(--background-green);
  fill: var(--background-green) !important;
  cursor: pointer;
}

.StatusWidgetStyles_container__mkteT {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}

.StatusWidgetStyles_wrapper__soZ_6 {
  background-color: #ffffff08;
  width: 500px;
  border-radius: 24px;
  padding: 24px;
}

@media (max-width: 576px) {
  .StatusWidgetStyles_wrapper__soZ_6 {
    width: 100%;
    margin: 24px;
  }
}

.StatusWidgetStyles_titleWrapper__zG6iJ {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: center;
  padding-top: 28px;
}

.StatusWidgetStyles_buttonContainer__nDfqH {
  display: flex;
  column-gap: 12px;
  padding-bottom: 14px;
  padding-top: 32px;
}

.StatusWidgetStyles_buttonContainer__nDfqH > div,
.StatusWidgetStyles_buttonContainer__nDfqH > button {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.StatusWidgetStyles_imgContainer__vtZiK {
  width: 100%;
  text-align: center;
  padding-top: 8px;
}

.PaymentConfirmationWidget_columnFlexContainer__Z_FFo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}

.PaymentConfirmationWidget_spinnerWrapper__6tN6v {
  width: 100%;
  text-align: center;
  padding-top: 8px;
}

.ReceiptUploadWidget_container__ot9Je {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}

.ReceiptUploadWidget_wrapper__Q__5c {
  background-color: #ffffff08;
  width: 500px;
  border-radius: 24px;
  padding: 24px;
}

@media (max-width: 576px) {
  .ReceiptUploadWidget_wrapper__Q__5c {
    width: 100%;
    margin: 24px;
  }
}

.ReceiptUploadWidget_inputWrapper__YDlFC {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ReceiptUploadWidget_titleWrapper__sThu7 {
  text-align: center;
  padding-top: 28px;
  padding-bottom: 48px;
}

.ReceiptUploadWidget_textarea__CLj6F {
  width: 100%;
  height: 100%;
  word-break: break-word;
  height: 110px;
}

.ReceiptUploadWidget_maxSymbols__wOQyO {
  color: #757c8d;
  font-size: 10px;
  line-height: 24px;
  font-weight: 600;
}

.ReceiptUploadWidget_inputContainer__dMGIG {
  padding: 15px 15px;
  display: flex;
  align-items: center;
  border: 1px solid #dddddd;
  border-radius: 10px;
  margin-top: 10px;
  background-color: #d9d9d9;
}

.ReceiptUploadWidget_inputContainer__dMGIG input {
  width: 100%;
}

.ReceiptUploadWidget_inputContainer__dMGIG input::placeholder {
  color: #756f6f;
}

.ReceiptUploadWidget_rowFlexContainer__C0p62 {
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  align-items: flex-start;
}

.ReceiptUploadWidget_columnContainer__Z_o_b {
  width: 250px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding-top: 30px;
}

.ReceiptUploadWidget_buttonContainer__sfcF7 {
  padding-bottom: 14px;
  padding-top: 8px;
}

.ReceiptUploadWidget_dragging__T9iXO {
  border: 2px dashed #007bff;
  background-color: rgba(0, 123, 255, 0.05);
}
.ReceiptUploadWidget_deleteButton__qpQ_8 {
  background: none;
  border: none;
  color: #979bb0;
  cursor: pointer;
  font-size: 16px;
  padding: 0 8px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease;
  height: 16px;
  min-height: 16px;
}

.ReceiptUploadWidget_deleteButton__qpQ_8:hover {
  color: #666;
}

.ReceiptUploadWidget_inputContainer__dMGIG {
  display: flex;
  align-items: center;
}

.ReceiptUploadWidget_fileInfo___2Blr {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.ReceiptUploadWidget_fileIcon__F4KXt {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.ReceiptUploadWidget_imagePreview__EDkHX {
  object-fit: cover;
  border-radius: 4px;
  flex-shrink: 0;
}

.RequisitesNotFoundWidget_titleContainer__DJHx8 {
  width: 100%;
}

.RequisitesNotFoundWidget_buttonsContainer__VBgAT {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.RequisitesNotFoundWidget_buttonsRow__m4Q5W {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.RequisitesNotFoundWidget_amountContainer__xfaf0 {
  display: flex;
  width: 100%;
  padding: 12px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: var(--main-border-radius);
  background: var(--background-primary);
}

.RequisitesNotFoundWidget_amountContainerTitle__uZ_0R {
  color: #979bb0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
}

.RequisitesNotFoundWidget_amountContainerAmount__tpG87 {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 590;
  line-height: 16px; /* 100% */
}

@media screen and (max-width: 720px) {
  .RequisitesNotFoundWidget_container__6hrGG {
    padding: 24px 24px;
  }

  .RequisitesNotFoundWidget_buttonsRow__m4Q5W {
    flex-direction: column;
  }
}

.RequisitesSearchFailureWidget_buttonsContainer__ThWyi {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.RequisitesSearchWidget_container__gQK2Q {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1;
  justify-content: center;
  position: relative;
}

@media (max-height: 500px) and (orientation: landscape) {
  .RequisitesSearchWidget_container__gQK2Q {
    padding-top: 80px;
  }
}

.RequisitesSearchWidget_progressBar__7zkAe {
  position: absolute;
  top: 0;
}

.RequisitesWidget_container__bK7JM {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.RequisitesWidget_wrapper__eQItD {
  background-color: #ffffff;
  max-width: 550px;
  border-radius: 24px;
  padding: 24px;
  display: grid;
}

.RequisitesWidget_title__PIOXB {
  position: relative;
  z-index: 1;

  text-align: center;
  margin-bottom: 8px;

  font-weight: bold;
  font-size: 24px;
  color: #000000;
}

.RequisitesWidget_timer__vHl0S {
  position: absolute;
  top: 0;
  right: 0;
}

.RequisitesWidget_subTitle__4rv3b {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #090f1f;
  margin-bottom: 24px;
}

.RequisitesWidget_buttons__BGB3R {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.RequisitesWidget_buttons__BGB3R > div {
  border-radius: 24px;
}

@media screen and (max-width: 720px) {
  .RequisitesWidget_title__PIOXB {
    font-size: 16px;
  }

  .RequisitesWidget_timer__vHl0S {
    top: -5px;
  }
}

.RequisitesWidgetCancelingModal_wrapper__gcnB5 {
  display: flex;
  flex-direction: column;

  max-width: 400px;
  gap: 10px;
}

.RequisitesWidgetCancelingModal_header__mjtYh {
  position: relative;
  z-index: 1;

  text-align: center;
}

.RequisitesWidgetCancelingModal_buttonClose__xpvaj {
  position: absolute;
  top: 4px;
  right: 0;
  z-index: 1;
}

.RequisitesWidgetCancelingModal_button__RZ_t_:hover {
  cursor: pointer;
  opacity: 0.5;
}

@media (max-width: 570px) {
  .RequisitesWidgetCancelingModal_buttonClose__xpvaj {
    top: -1px;
  }
}

.RequisitesWidgetCancelingModal_buttons__FFwhU {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  padding-top: 20px;
}

.RequisitesWidgetCancelingModal_spinner__T3TfT {
  display: flex;
  justify-content: center;
}

.RequisitesWidgetPaymentInstruction_wrapper__1d0hP {
  display: flex;
  flex-direction: column;

  max-width: 400px;
  gap: 10px;
}

.RequisitesWidgetPaymentInstruction_header__CiKKs {
  position: relative;
  z-index: 1;

  text-align: center;
}

.RequisitesWidgetPaymentInstruction_buttonClose__QAu3w {
  position: absolute;
  top: 4px;
  right: 0;
  z-index: 1;
}

.RequisitesWidgetPaymentInstruction_button__pGaGM:hover {
  cursor: pointer;
  opacity: 0.5;
}

.RequisitesWidgetPaymentInstruction_instructionText__rTdqT {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  text-align: left;
}

@media (max-width: 570px) {
  .RequisitesWidgetPaymentInstruction_buttonClose__QAu3w {
    top: -1px;
  }
}

.RequisitesWidgetCredentials_credentials__6l879 {
  position: relative;
  z-index: 1;

  max-width: 502px;
  height: 200px;
  padding: 16px;
  margin-bottom: 24px;
  border-radius: 24px;

  display: grid;
  grid-template-columns: 4fr 1fr;
  align-items: center;
  justify-content: space-between;

  background: linear-gradient(99deg, #25a458 0%, #058d44 100%);
}

.RequisitesWidgetCredentials_credentials__6l879 div:nth-child(even) {
  justify-self: end;
}

.RequisitesWidgetCredentials_credentials__6l879::before {
  content: attr(data-bank);
  color: #ffffff3d;
  padding-right: 24px;
  font-size: 55px;
  line-height: 132px;
  font-weight: bold;
  text-align: right;

  display: inline-block;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;

  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

@media screen and (min-width: 520px) {
  .RequisitesWidgetCredentials_credentials__6l879::before {
    width: 400px;
  }
}

.RequisitesWidgetCredentials_credentialsTextWrapper__Ced8p {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.RequisitesWidgetCredentials_credentialsText__8_cZ5 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

.RequisitesWidgetCredentials_button__CszIY {
  cursor: pointer;
}

.RequisitesWidgetCountdownTimer_timer__pBeqY {
  width: 60px;
  height: 31px;
  background-color: #979bb0;
  border-radius: 24px;
  padding: 8px;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

.RequisitesWidgetWarning_container__UpZx6 {
  display: flex;
  align-items: center;
  padding: 16px;

  border: 1px #fa5959 solid;
  border-radius: 24px;
  margin-bottom: 24px;
}

.RequisitesWidgetWarning_iconWrapper__sDjtW {
  width: 24px;
  height: 24px;
}

.RequisitesWidgetPaymentAltInstruction_wrapper__SxLuM {
  display: flex;
  flex-direction: column;

  max-width: 400px;
  gap: 10px;
}

.RequisitesWidgetPaymentAltInstruction_header__Uxn4D {
  position: relative;
  z-index: 1;

  text-align: center;
}

.RequisitesWidgetPaymentAltInstruction_buttonClose__xO6bU {
  position: absolute;
  top: 4px;
  right: 0;
  z-index: 1;
}

.RequisitesWidgetPaymentAltInstruction_button__aVLT_:hover {
  cursor: pointer;
  opacity: 0.5;
}

.RequisitesWidgetPaymentAltInstruction_instructionsButton__7f3db {
  background-color: #007bff;
  color: #ffffff;
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition:
    background-color 0.3s,
    transform 0.2s;
}

.RequisitesWidgetPaymentAltInstruction_instructionsButton__7f3db:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.RequisitesWidgetPaymentAltInstruction_instructionsButton__7f3db:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

@media (max-width: 570px) {
  .RequisitesWidgetPaymentAltInstruction_buttonClose__xO6bU {
    top: -1px;
  }
}

.RequisitesWidgetPaymentAltInstructionComponent_wrapper__xUE1e {
  display: 'flex';
  flex-direction: 'column';
  justify-content: 'center';
  color: #ffffff;
}

.RequisitesWidgetPaymentAltInstructionComponent_title__23yup {
  font-size: 24px;
  line-height: 31px;
  color: #ffffff;
  font-weight: 600;
  padding-top: 6px;
}

.RequisitesWidgetPaymentAltInstructionComponent_list__kWUIm {
  padding: 10px 0 15px 0;
}

.RequisitesWidgetPaymentAltInstructionComponent_item__RC5wl {
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 10px;
}

.RequisitesWidgetPaymentAltInstructionComponent_img__IZBoa {
  margin-bottom: 15px;
}

.RequisitesWidgetPaymentAltInstructionComponent_note__d4wna {
  font-size: 16px;
  line-height: 24px;
  color: #cb912f;
  margin-bottom: 15px;
}

.RequisitesWidgetPaymentAltInstructionComponent_finalText__rGBvg {
  font-size: 30px;
  line-height: 39px;
  color: #ffffff;
}

.SelectPaymentMethodWidget_paymentContainer__8LsDN {
  background-color: #fff;
  border-radius: 24px;
  padding: 20px;
  width: 420px;
}

.SelectPaymentMethodWidget_amountContainer__8YP_M {
  background-color: #ffffff12;
  border-radius: 24px;
  padding: 20px;
  width: 420px;
}

.SelectPaymentMethodWidget_container__OUGUO {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  gap: 8px;
}

@media (max-width: 576px) {
  .SelectPaymentMethodWidget_paymentContainer__8LsDN {
    width: 100%;
  }

  .SelectPaymentMethodWidget_amountContainer__8YP_M {
    width: 100%;
  }

  .SelectPaymentMethodWidget_container__OUGUO {
    flex-direction: column;
    height: 100%;
  }
}

.SelectPaymentMethodWidget_option__D2uMG {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid transparent;
  border-radius: 24px;
  padding: 16px;
  position: relative;
  cursor: pointer;
  transition: border-color 0.3s;
  border-color: #50d866;
  height: 100%;
}

.SelectPaymentMethodWidget_option__D2uMG:hover {
  opacity: 0.5;
}

.SelectPaymentMethodWidget_selected__qYvQ1 {
  background-color: #7afb8f;
}

.SelectPaymentMethodWidget_radio__0MiPy {
  display: none;
}

.SelectPaymentMethodWidget_inputContainer__BPLzU {
  display: flex;
  border: 1px solid #50d866;
  border-radius: 99px;
  column-gap: 10px;
  padding: 4px;
  width: 100%;
  color: white;
}

.SelectPaymentMethodWidget_disabled__5AZAB {
  opacity: 1;
}

.SelectPaymentMethodWidget_input__djQVR {
  width: 100%;
  color: white;
  padding-right: 10px;
}

.SelectPaymentMethodWidget_input__djQVR::placeholder {
  color: white;
}

.SelectPaymentMethodWidget_amountWrapper__Rixwv {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.SelectPaymentMethodWidget_jamboWrapper__lnBns {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: -2px;
  margin-right: -2px;
}

.SelectPaymentMethodWidget_relativeCard__7ygcg {
  position: relative;
  width: 100%;
}

.SelectPaymentMethodWidget_relativeCardDisabled__ZjSSG {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.SelectPaymentMethodWidget_relativeCard__7ygcg:hover {
  opacity: 0.5;
}

.SelectBankWidget_container__MJECb {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;
}

.SelectBankWidget_bankContainer__ZNdDT {
  background-color: #fff;
  border-radius: 24px;
  padding: 20px;
  max-width: 900px;
  width: 900px;
  min-height: 500px;
  height: 500px;
  overflow-y: auto;
  scrollbar-width: thin;
}
.SelectBankWidget_searchContainer__6PSy9 {
  display: flex;
  column-gap: 10px;
  width: 100%;
  align-items: center;
}

.SelectBankWidget_header__q9jZR {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 992px) {
  .SelectBankWidget_container__MJECb {
    flex-direction: column;
    height: 50%;
    min-width: 100%;
  }

  .SelectBankWidget_bankContainer__ZNdDT {
    width: 100%;
    min-height: 450px;
    height: calc(100vh - 250px);
  }
}

.SelectBankWidget_option__vRZZv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid transparent;
  border-radius: 24px;
  padding: 16px;
  position: relative;
  cursor: pointer;
  transition: border-color 0.3s;
  border-color: #50d866;
  height: 100%;
}

.SelectBankWidget_selected__C2nFE {
  background-color: #7afb8f;
}

.SelectBankWidget_radio__OzeDk {
  display: none;
}

.SelectBankWidget_inputContainer__sK_Za {
  display: flex;
  border: 1px solid #50d866;
  border-radius: 99px;
  column-gap: 10px;
  padding: 4px;
  width: 100%;
  color: white;
}

.SelectBankWidget_disabled__5KNYr {
  opacity: 0.2;
}

.SelectBankWidget_input__CrsYS {
  width: 100%;
  color: white;
  padding-right: 10px;
}

.SelectBankWidget_input__CrsYS::placeholder {
  color: #979bb0;
}

.SelectBankWidget_input__CrsYS::placeholder {
  color: #979bb0;
}

.SelectBankWidget_amountWrapper___wadr {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.SelectBankWidget_jamboWrapper__OxZKW {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: -2px;
  margin-right: -2px;
}

.SelectBankWidget_relativeCard__zn0FU {
  position: relative;
  width: 100%;
}

.SelectBankWidget_noMatches__ghcMD {
  color: #979bb0;
  width: 100%;
  text-align: center;
}

.SelectBankWidget_bankItems__D0x1f {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  grid-gap: 8px;
  gap: 8px;
}

.SelectBankWidget_buttonContainer__OC8a6 {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.SelectBankWidget_buttonWrapper__mcdiT {
  display: flex;
  gap: 8px;
  width: 50%;
}

@media (max-width: 870px) {
  .SelectBankWidget_bankItems__D0x1f {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 720px) {
  .SelectBankWidget_bankItems__D0x1f {
    grid-template-columns: repeat(3, 1fr);
  }

  .SelectBankWidget_buttonWrapper__mcdiT {
    width: 100%;
  }
}

@media (max-width: 530px) {
  .SelectBankWidget_bankItems__D0x1f {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 370px) {
  .SelectBankWidget_bankItems__D0x1f {
    grid-template-columns: repeat(1, 1fr);
  }
}

.PageNotFoundWidget_container__Iuo7A {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
}

.PageErrorWidget_container__Zkn0f {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
}

