.container {
  display: flex;
  gap: 10px;
  align-items: center;

  color: var(--text-secondary);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  fill: var(--text-secondary);

  margin: 25px 0;
}

.container:hover {
  color: var(--background-green);
  fill: var(--background-green) !important;
  cursor: pointer;
}
