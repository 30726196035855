.timer {
  width: 60px;
  height: 31px;
  background-color: #979bb0;
  border-radius: 24px;
  padding: 8px;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}
